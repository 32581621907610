import React, { useState } from "react";

import { useParams, Link, useHistory } from "react-router-dom";
import {
  Modal,
  Box,
  Button,
  ButtonGroup,
  Grid,
  CircularProgress,
  useMediaQuery,
  Rating,
  Typography,
} from "@mui/material";

import { ArrowBack } from "@mui/icons-material";
import useStyles from "./styles";

import {
  useGetActorDetailsQuery,
  useGetActorMoviesQuery,
  useGetMoviesByActorIdQuery
} from "../../services/TMDB";
import { MoviesList ,Pagination } from "../index";
import ActorMovies from "../ActorMovies/ActorMovies";

const Actors = () => {
  const { id } = useParams();
  const [Page, setPage] = useState(1);
  console.log(id);
  
  const { data, isFetching, error } = useGetActorDetailsQuery(id);
  // const { data: movies, isFetching: isMovieFetching } =
  //   useGetActorMoviesQuery(id);

  const { data: movies, isFetching: isMovieFetching } =
  useGetMoviesByActorIdQuery({id, Page});

    console.log(movies);
  // console.log(movies?.cast);
  // const Movies = movies?.cast;
  const classes = useStyles();
  // const d = new Date(`${data?.birthday}`);
  // const history=useHistory();
  // const weekday = [
  //   "Sunday",
  //   "Monday",
  //   "Tuesday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];
  // const month = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "September",
  //   "October",
  //   "November",
  //   "December",
  // ];

  // const nameOfday = weekday[d.getDay()];
  // const mon = month[d.getMonth()];
  // const day = d.getDate();
  // const year = d.getFullYear();

  // const day = d.getDay();

  // console.log(year);
  // console.log(d);

  // console.log(data);

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size="8rem" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          startIcon={<ArrowBack />}
          onClick={() => history.goBack()}
          color="primary"
        >
          Go back
        </Button>
      </Box>
    );
  }
  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item lg={5} xl={4}>
        <img
          className={classes.actorImage}
          src={`https://image.tmdb.org/t/p/w500/${data?.profile_path}`}
          alt={data?.name}
        />
      </Grid>
      <Grid
        item
        lg={7}
        xl={8}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}

        //  className={classes.actorDetails}
      >
        <Typography
          variant="h2"
          gutterBottom
          align="left"
          sx={{ fontWeight: "400" }}
        >
          {" "}
          {data?.name}
        </Typography>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: "400" }}>
          {" "}
          {/* Born: {nameOfday} {mon} {day} {year}{" "} */}
          Born : {new Date(data?.birthday).toDateString()}
        </Typography>

        <Typography gutterBottom variant="body1" align="justify" paragraph>
          {data?.biography || "Sorry no biography Yet.."}
        </Typography>

        <Box marginTop='2rem' display='flex' justifyContent='space-around' >
        <Button
            color="primary"
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.imdb.com/name/${data?.imdb_id}`}
          >
            IMDB
          </Button>
          <Button
            endIcon={<ArrowBack />}
            sx={{ borderColor: "main.primary" }}
          onClick={() =>history.goBack()}
          color="primary"
          >
            <Typography
              color="inherit"
              variant="subtitle2"
              sx={{ textDecoration: "none" }}
            >
              Back
            </Typography>
          </Button>
        </Box>
        {/* <ButtonGroup size="medium" className={classes.buttonsContainer}>
          <Button
            color="primary"
            variant="contained"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.imdb.com/name/${data?.imdb_id}`}
          >
            IMDB
          </Button>
          <Button
            endIcon={<ArrowBack />}
            sx={{ borderColor: "main.primary" }}
            component={Link}
            to="/"
          >
            <Typography
              color="inherit"
              variant="subtitle2"
              sx={{ textDecoration: "none" }}
            >
              Back
            </Typography>
          </Button>
        </ButtonGroup> */}
      </Grid>
      <Box margin="2rem 0" width="100%">
        <Typography gutterBottom align="center" variant="h2">
          Movies
        </Typography>
        {movies ? (
        /* <ActorMovies movies={movies} numberOfMovies={12} /> */
        <MoviesList movies={movies} numberOfMovies={12} />
      ) : (
        <Box>Sorry no thing was found</Box>
      )}
      <Pagination currentPage={Page} setPage={setPage} totalPages={movies?.total_pages} />
      </Box>
     
    </Grid>
  );
};

export default Actors;
