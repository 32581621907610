import { configureStore } from "@reduxjs/toolkit";
import { tmdbApi } from "../services/TMDB";

import  genreOrCategoryReducer  from "../features/CurrentGenreOrCatogery";
import  userReducer   from "../features/auth";


export  default configureStore ({
    reducer:{
      [tmdbApi.reducerPath]:tmdbApi.reducer,
      currentGenreOrCatogery: genreOrCategoryReducer,
      user: userReducer
    },
})