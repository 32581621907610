import React, { useEffect, useState } from "react";

import {
  Modal,
  Box,
  Button,
  ButtonGroup,
  Grid,
  CircularProgress,
  useMediaQuery,
  Rating,
  Typography,
} from "@mui/material";

import {
  Movie as MovieIcon,
  Theaters,
  Language,
  PlusOne,
  Favorite,
  FavoriteBorderOutlined,
  Remove,
  arrow_back,
  ArrowBack,
} from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { useGetMovieQuery ,useGetRecommendationsQuery,useGetListQuery } from "../../services/TMDB";
import genreIcons from "../../assets/genres/index";
import { selectGenreOrCategory } from "../../features/CurrentGenreOrCatogery";

import useStyles from "./styles";

import {MoviesList,Pagination} from '../index'
import { userSelector } from "../../features/auth";

const MovieInformation = () => {
  console.log("Movie Information");

  const { id } = useParams();
  const {user} = useSelector(userSelector);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [Page, setPage] = useState(1);

  const { data, isFetching, error } = useGetMovieQuery(id);
  const{data:favoriteMovies} = useGetListQuery({listName:'favorite/movies',accountId:user.id ,sessionId:localStorage.getItem('session_id'),Page:1}); 
  const{data:watchlistMovies} = useGetListQuery({listName:'watchlist/movies',accountId:user.id ,sessionId:localStorage.getItem('session_id'),Page:1}); 
  const {data:recommendations, isFetching:isRecommendationsFetching} = useGetRecommendationsQuery({list:'/recommendations',movie_id:id , Page  });

  console.log(recommendations)
  const classes = useStyles();

const [isMovieFavorited, setisMovieFavorited] = useState(false);
const [isMovieWatchListed, setisMovieWatchListed] = useState(false);
useEffect(() => {
  setisMovieWatchListed(!! watchlistMovies?.results?.find((movie)=>movie?.id === data?.id))
}, [watchlistMovies,data]);
useEffect(() => {
  setisMovieFavorited(!! favoriteMovies?.results?.find((movie)=>movie?.id === data?.id))
}, [favoriteMovies,data]);
  const addToFavorite =  async (
   ) => {
    await axios.post(`https://api.themoviedb.org/3/account/${user.id}/favorite?api_key=${process.env.REACT_APP_TMDB_KEY}&session_id=${localStorage.getItem('session_id')}`,{
      media_type:'movie',
      media_id:id,
      favorite:!isMovieFavorited
    });

    setisMovieFavorited((prev)=>!prev)

   }
  const addToWatchList = async  () => {
    await axios.post(`https://api.themoviedb.org/3/account/${user.id}/watchlist?api_key=${process.env.REACT_APP_TMDB_KEY}&session_id=${localStorage.getItem('session_id')}`,{
      media_type:'movie',
      media_id:id,
      watchlist:!isMovieWatchListed
    });

    setisMovieWatchListed((prev)=>!prev)
  }

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size="8rem" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Link to="/">Something went wrong - Go Back</Link>
      </Box>
    );
  }

  // console.log(data);

  return (
    <Grid container className={classes.containerSpaceAround}>
      <Grid item sm={12} lg={4} style={{display: 'flex' , justifyContent:"center", alignItems:"center" , marginBottom:'30px' }}>
        <img
          className={classes.poster}
          src={`https://image.tmdb.org/t/p/w500/${data?.poster_path}`}
          alt={data?.title}
        />
      </Grid>
      <Grid item container direction="column" lg={7}>
        <Typography variant="h3" align="center" gutterBottom>
          {data?.title}({data?.release_date.split("-")[0]})
        </Typography>
        <Typography variant="h5" align="center" gutterBottom>
          {data?.tagline}
        </Typography>
        <Grid item className={classes.containerSpaceAround}>
          <Box display="flex" align="center">
            <Rating readOnly value={data.vote_average / 2} />
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ marginLeft: "10px" }}
            >
              {data?.vote_average}/10
            </Typography>
          </Box>
          <Typography variant="h6" align="center" gutterBottom>
            {data?.runtime}min
            
              | Language: {data?.spoken_languages[0].name}
              
          </Typography>
        </Grid>
        <Grid item className={classes.genresContainer}>
          {data?.genres?.map((genre, i) => (
            <Link
              key={genre.name}
              className={classes.links}
              to="/"
              onClick={() => dispatch(selectGenreOrCategory(genre.id))}
            >
              <img
                src={genreIcons[genre?.name.toLowerCase()]}
                className={classes.genreImage}
                height={30}
                alt=""
              />
              <Typography variant="subtitle1" color="textPrimary"  >
                {genre?.name}
              </Typography>
            </Link>
          ))}
        </Grid>
        <Typography variant="h5" gutterBottom style={{ marginTop: "10px" }}>
          Overview
        </Typography>
        <Typography style={{ marginBottom: "2rem" }}>
          {data?.overview}
        </Typography>
        <Typography variant="h5" gutterBottom>
          {" "}
          Top Cast
        </Typography>
        <Grid item container spacing={2}>
          {data &&
            data.credits?.cast
              ?.map(
                (character, i) =>
                  character.profile_path && (
                    <Grid
                      item
                      key={i}
                      xs={4}
                      md={2}
                      component={Link}
                      to={`/actors/${character.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        className={classes.castImage}
                        src={`https://image.tmdb.org/t/p/w500/${character.profile_path}`}
                        alt={character.name}
                      />
                      <Typography color="textPrimary">
                        {character?.name}
                      </Typography>
                      <Typography color="textSecondary">
                        {character?.character.split("/")[0]}
                      </Typography>
                    </Grid>
                  )
              )
              .slice(0, 6)}
        </Grid>
        <Grid item container style={{ marginTop: "2rem" }}>
          <div className={classes.buttonsContainer}>
            <Grid item xs={12} sm={6} 
            
            className={classes.buttonsContainer}
            >
              <ButtonGroup size="medium"  variant="outlined">
                <Button
                  target="_blank"
                  rel="noopener noreferrer"
                  href={data?.homepage}
                  endIcon={<Language />}
                >
                  {" "}
                  Website
                </Button>
                <Button
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.imdb.com/title/${data?.imdb_id}`}
                  endIcon={<MovieIcon />}
                >
                  {" "}
                  IMDB
                </Button>
                <Button onClick={() => setOpen(true)} href="#" endIcon={<Theaters />}>
                  Trailer
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12} sm={6} 
            
            className={classes.buttonsContainer}
            
            >
              <ButtonGroup size="medium" variant="outlined">
                <Button onClick={addToFavorite} endIcon={isMovieFavorited?<Favorite/>:<FavoriteBorderOutlined/>}  >
                  {isMovieFavorited?' UnFavorite':'Favorite'}
                </Button>
                <Button onClick={addToWatchList} endIcon={isMovieWatchListed?<PlusOne/>:<Remove/>}  >
                  {/* {isMovieWatchListed?' Remove from Watchlist':'Add to Watchlist'} */}
                  Watchlist
                </Button>
                <Button endIcon={<ArrowBack/>} sx={{borderColor:'primary.main' }} >
                <Typography  component={Link} to="/" color='inherit' variant='subtitle2' sx={{textDecoration:'none'}} >Back</Typography>
                </Button>
              </ButtonGroup>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Box marginTop='5rem' width='100%'>
        <Typography variant='h3' gutterBottom align='center'>
          You might also like
        </Typography>
        {recommendations ? <MoviesList movies={recommendations} numberOfMovies={12}/> :<Box> Sorry, nothing was found. </Box>  }
        <Pagination currentPage={Page} setPage={setPage} totalPages={recommendations?.total_pages} />

      </Box>
      <Modal
        closeAfterTransition
        className={classes.modal}
        open={open}
        onClose={()=>setOpen(false)} 
        >
          {data?.videos?.results?.length>0 && (
            <iframe
               autoPlay 
               className={classes.video}
               frameBorder='0'
               title='Trailer'
               src= {`https://www.youtube.com/embed/${data.videos.results[0].key}`}
               allow='autoPlay' />
          )}
        </Modal>
    </Grid>
  );
};

export default MovieInformation;
