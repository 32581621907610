import React, { useState } from "react";
import {Box,CircularProgress,useMediaQuery,Typography} from '@mui/material'

import { useSelector } from "react-redux";

import { useGetMoviesQuery } from "../../services/TMDB";
import {MoviesList,Pagination,FeaturedMovies} from "../index";
import { selectGenreOrCategory } from "../../features/CurrentGenreOrCatogery";


const Movies = () => {
  const [Page, setPage] = useState(1);
  const {genreIdOrCategoryName,searchQuery} =  useSelector((state)=>state.currentGenreOrCatogery)


  const {data,error,isFetching} =useGetMoviesQuery({genreIdOrCategoryName,Page,searchQuery});

  const lg =useMediaQuery((theme)=>theme.breakpoints.only('lg'));
  const numberOfMovies = lg ? 17 : 19;

  if(isFetching) {
    return (
      <Box display='flex' justifyContent='center' >
        <CircularProgress size='4rem'/>
      </Box>
    )
  }

  if (!data.results.length) {
    return (
      <Box display='flex' alignItems='center' mt='20px'>
        <Typography variant='h4' >
          No movies that match the name . 
          <br/>
          Please search for something else.
        </Typography>
      </Box>
    )
  }

  if (error) return ('An error has occurred')

  // console.log(data)

  return <div>
      <FeaturedMovies movie={data.results[0]} />
    <MoviesList movies={data} numberOfMovies={numberOfMovies} excludeFirst/>
    <Pagination currentPage={Page} setPage={setPage} totalPages={data?.total_pages} />
  </div>;
};

export default Movies;
