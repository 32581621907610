import react from "react";

import ReactDOM from "react-dom";

import App from "./components/App";

import { BrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { Provider } from "react-redux";
import store from './app/store'

import ToggleColorModeProvider from "./utils/ToggleColorMode";

import './index.css'

// const theme = createTheme({});

ReactDOM.render(
  <Provider store={store} >
    <ToggleColorModeProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ToggleColorModeProvider>
  </Provider>,
  document.getElementById("root")
);
