import React, { useEffect ,useContext } from 'react'
import alanBtn from '@alan-ai/alan-sdk-web';
import {ColorModeContext} from '../utils/ToggleColorMode'
import { useDispatch } from 'react-redux';

import { useHistory } from 'react-router-dom';

import {fetchToken} from '../utils/index'
import { selectGenreOrCategory , searchMovie } from '../features/CurrentGenreOrCatogery';


const useAlan = () => {
    const  {setmode}= useContext(ColorModeContext)

    const dispatch = useDispatch();
    const history = useHistory ();
    useEffect(() => {
        alanBtn({
            key: 'abd99a124998cff08c349cb62135c4cf2e956eca572e1d8b807a3e2338fdd0dc/stage',
            onCommand: ({command,mode,genres,genreOrCategory,query}) => {

                if (command === 'chooseGenre') {
                  const foundGenre = genres.find((g)=>g.name.toLowerCase() === genreOrCategory.toLowerCase());

                  if(foundGenre){
                    history.push('/');
                    dispatch(selectGenreOrCategory(foundGenre.id))
                  } else {
                    const category = genreOrCategory.startsWith('top')? 'top_rated': genreOrCategory;
                    history.push('/');
                    dispatch(selectGenreOrCategory(category))
                  }
                }
              else if (command === 'changeMode') {
                 if(mode === 'light') {
                    setmode('light')
                 } else {
                    setmode('dark')

                 }
               } else if (command==='login')
                 {fetchToken();} else if (command==='logout') {
                    localStorage.clear();
                    history.push('/');
                 } else if (command==='search'){
                    dispatch(searchMovie(query));
                 }
            }
        });
      }, []);
 
}

export default useAlan
