import {makeStyles} from '@mui/styles';


export default makeStyles ((theme)=>({
container:{
    display: 'flex',
    justifyContent:'space-around',
    margin:'10px 0 !important',
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        flexWrap: "wrap",
      },
},
actorImage:{
 borderRadius:'20px',
 boxShadow:'.5em 1em 1em rgba(64,64,70)',
 width:'90%',
 objectFit:'cover',
 [theme.breakpoints.down("md")]: {
    margin: "0 auto",
    width: "50%",
    height: "350px",
  },
  [theme.breakpoints.down("sm")]: {
    margin: "0 auto",
    width: "100%",
    height: "350px",
    marginBottom: "30px",
  },
},
buttonsContainer:{
    display: "flex",
    width: "100%",
    justifyContent:'space-around'

}

}))