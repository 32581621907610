import React, { useEffect, useState } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Drawer,
  Button,
  Avatar,
  useMediaQuery,
} from "@mui/material";
import {
  AccountCircle,
  Menu,
  Brightness4,
  Brightness7,
} from "@mui/icons-material";

import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import {fetchToken,createSessionId,moviesApi} from '../../utils/index'

import useStyles from "./styles";
import { Sidebar,Search } from "../index";

import { useDispatch, useSelector } from "react-redux";

import {setUser,userSelector} from '../../features/auth'

import { ColorModeContext } from "../../utils/ToggleColorMode";
import { useContext } from "react";



const NavBar = () => {

  const {isAuthenticated,user}= useSelector(userSelector)
  const [mobileOpen, setmobileOpen] = useState(false);
  const classes = useStyles();
  const isMobile = useMediaQuery(" (max-width:600px)");

  const theme = useTheme();

  // const isAuthenticated = false;

  console.log(user);

  const token = localStorage.getItem('request_token');
  const sessionIdFromLocalStorage = localStorage.getItem('session_id');

  const dispatch = useDispatch()

  const colorMode = useContext(ColorModeContext)


  useEffect(() => {
    const logInUser = async ()=>{
      if (token){
        if (sessionIdFromLocalStorage){
             const {data:userData} = await moviesApi.get(`/account?session_id=${sessionIdFromLocalStorage}`)
             dispatch(setUser(userData));

             console.log(1)

        } else {
          const sessionId = await createSessionId();
          const {data:userData} = await moviesApi.get(`/account?session_id=${sessionId}`)
          dispatch(setUser(userData));
          console.log(2)

        }
      }
    };

    logInUser();
    
  }, [token]);

  console.log(user)

  return (
    <div>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="end"
              style={{ outline: "none" }}
              onClick={() => setmobileOpen((prevmobileOpen)=> !prevmobileOpen)}
              className={classes.menuButton}
            >
              <Menu />
            </IconButton>
          )}

          <IconButton color="inherit" sx={{ ml: 1 }} onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === "dark" ? <Brightness7 /> : <Brightness4 />}
          </IconButton>
          {!isMobile && <Search/>}
          <div>
            {!isAuthenticated ? (
              <Button color="inherit" onClick={fetchToken}>
                Login &nbsp;
                <AccountCircle />
              </Button>
            ) : (
              <Button
                color="inherit"
                component={Link}
                to= {`/profile/${user.id}`}
                className={classes.linkButton}
                onClick={() => {}}
              >
                {!isMobile && <>My Movies &nbsp; </>}
                <Avatar
                  style={{ width: 30, height: 30 }}
                  alt="profile"
                  src={`https://www.themoviedb.org/t/p/w64_and_h64_face${user?.avatar?.tmdb?.avatar_path}`}
                ></Avatar>
              </Button>
            )}
          </div>
          {isMobile &&  <Search/>}
        </Toolbar>
      </AppBar>
      <div className={classes.drawer}>
        {isMobile ? (
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={()=> setmobileOpen((prevmobileOpen)=> !prevmobileOpen)}
            // className={classes.drawerBackground}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <Sidebar setMobileOpen={setmobileOpen} />
          </Drawer>
        ) : (
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant="permanent"
            open
          >
            <Sidebar setMobileOpen={setmobileOpen} />
          </Drawer>
        )}
      </div>

    </div>
  );
};

export default NavBar;
