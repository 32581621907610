import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const tmdbApikey = process.env.REACT_APP_TMDB_KEY;
// const page = 1;

// https://api.themoviedb.org/3/movie/popular?api_key=<<api_key>>&language=en-US&page=1
export const tmdbApi = createApi({
  reducerPath: "tmdbApi",
  baseQuery: fetchBaseQuery({ baseUrl: `https://api.themoviedb.org/3` }),
  endpoints: (builder) => ({
    //Get Genres
    getGenres: builder.query({
      query: () => `genre/movie/list?api_key=${tmdbApikey}`,
    }),
    // Get Movies by [Type]

    getMovies: builder.query({
      query: ({ genreIdOrCategoryName, Page, searchQuery }) => {
        //*Get Movies by search

        if (searchQuery) {
          return `/search/movie?query=${searchQuery}&page=${Page}&api_key=${tmdbApikey}`;
        }
        //*Get movies by category
        if (
          genreIdOrCategoryName &&
          typeof genreIdOrCategoryName === "string"
        ) {
          return `movie/${genreIdOrCategoryName}?page=${Page}&api_key=${tmdbApikey}`;
        }
        //* Get movies by genre

        if (
          genreIdOrCategoryName &&
          typeof genreIdOrCategoryName === "number"
        ) {
          return `discover/movie?with_genres=${genreIdOrCategoryName}&page=${Page}&api_key=${tmdbApikey}`;
        }
        //* Get popular movies

        return `movie/popular?page=${Page}&api_key=${tmdbApikey}`;
      },
    }),

    //* Get Movie

    getMovie: builder.query({
      query: (id) =>
        `/movie/${id}?append_to_response=videos,credits&api_key=${tmdbApikey}`,
    }),
    //* Get User specific Lists

    getList: builder.query({
      query: ({listName, Page,accountId,sessionId}) =>`/account/${accountId}/${listName}?api_key=${tmdbApikey}&session_id=${sessionId}&page=${Page}`
    }),

    getRecommendations: builder.query({
      query: ({ movie_id, list ,Page }) =>
        `/movie/${movie_id}/${list}?page=${Page}&api_key=${tmdbApikey}`,
    }),

    //* Get Actor Details
    getActorDetails: builder.query({
      query: (id) => `person/${id}?api_key=${tmdbApikey}`,
    }),
    //* Get Actor Movies
    //  getActorMovies: builder.query({
    //   query: (id)=>`person/${id}/movie_credits?api_key=${tmdbApikey}`
    //  })

    getMoviesByActorId: builder.query({
      query: ({ id, Page }) =>
        `/discover/movie/?with_cast=${id}&page=${Page}&api_key=${tmdbApikey}`,
    }),
  }),
});

export const {
  useGetGenresQuery,
  useGetMoviesQuery,
  useGetMovieQuery,
  useGetRecommendationsQuery,
  useGetListQuery,
  useGetActorDetailsQuery,
  useGetActorMoviesQuery,
  useGetMoviesByActorIdQuery,
} = tmdbApi;
